#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #fcf5f9bc;
    height: 100%;
    font-family: 'Open Sans', sans-serif;
}

body {
    background-color: #fadded4e;
}

.bg-light {
    padding: 20px;
    background-color: white !important
}

.no-select { /* navbar language selector */
    user-select: none;
    cursor:pointer;
    color: hsl(327, 100%, 28%) !important;
    padding: 8px;
    word-break: keep-all;
}

h1 {
    font: bold normal 60px / 75px "PT Serif", Georgia, Times, serif;
    font-size: 2em;
    color: #90004F;
    letter-spacing: -0.015em;
    line-height: 38px;
    padding-bottom: 20px;
}

h2 {
    font: bold normal 60px / 75px "PT Serif", Georgia, Times, serif;
    font-size: 1.5em;
    color: #90004F;
    letter-spacing: -0.015em;
    line-height: 38px;
    padding-bottom: 20px;
}

h3 {
    font: bold normal 60px / 75px "PT Serif", Georgia, Times, serif;
    font-size: 1.25em;
    color: #90004F;
    letter-spacing: -0.015em;
    line-height: 38px;
    padding-bottom: 20px;
}

p {
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
}

a.button, button  { 
    display: inline-block;
    margin: 5px;
    text-decoration: none !important;
    /* font-size: 16px; */
    line-height: normal;
    font-weight: bold;
    color: white !important;
    background: hsl(327, 100%, 28%) !important;
    padding: 15px 40px;
    vertical-align: middle;
    border-radius: 100px;
    border: 0 !important;
    outline: 0 !important;
    text-align: center;
}

.btn
{
    border-radius: 100px !important;
    padding-left: 20px;
    padding-right: 20px;
}

.btn-secondary {
    background: hsl(327, 100%, 20%) !important;
}

.btn.remove {
    padding-left: 6px;
    padding-right: 6px;
    width: 36px;
    background: hsl(327, 100%, 30%) !important;
}

.card-title {
    color: #90004F !important;
}

/* Navbar and Footer */
.navbar-nav, .nav-item {
    padding-inline: 15px;
}

.nav-link {
    color: #90004F !important;
    font-weight: 300 !important;
}

.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
    font-weight: 700 !important;
}

.nav-footer {
    border-radius: 80px 80px 0 0;
    background-color: white;
    min-height: 5vh;
}

.nav-footer-logo {
    max-width: 100%;

}

.nav-footer h5 {
    margin-bottom: 15px;
}

.nav-footer ul {
    padding-left: 0;
}

.nav-footer ul li {
    list-style: none;
}

.nav-footer ul li a {
    color: inherit;
    text-decoration: none;
}

.nav-footer ul li a:hover {
    text-decoration: underline;
}

.nav-footer-text {
    color: black;
}

.custom-dropdown {
    padding-right: 0px;
}

.custom-dropdown .dropdown-toggle::after {
    color: #841a4e; 
    border-top-color: #841a4e; 
}
  
.custom-dropdown .dropdown-menu .dropdown-item {
    color: #501a37;
}

.custom-dropdown .dropdown-menu .dropdown-item:hover,
.custom-dropdown .dropdown-menu .dropdown-item:focus {
    color: #841a4e;
}

.dropdown-menu{
    border-color: #841a4d49;
}